<template>
  <!-- 关键词 文章列表 -->
  <div class="container">
    <!-- 内容 -->
    <div class="center">
      <div class="content">
        <!-- 快讯详细信息 -->
        <div class="left">
          <!-- 文章列表 -->
          <div class="list">
            <V-ArticleList :newarticlelist="newarticlelist"></V-ArticleList>
          </div>
          <br />
          <!-- 分页 -->
          <div class="page-nav">
            <el-pagination
              background
              layout="prev, pager, next"
              :hide-on-single-page="hidePageNav"
              :total="totlePage"
              :page-size="pageSize"
              @prev-click="prevPage"
              @next-click="nextPage"
              @current-change="currentPage"
            ></el-pagination>
          </div>
        </div>
        <!-- 24小时热榜 -->
      </div>
    </div>
    <div class="right">
      <V-HotType></V-HotType>
    </div>
  </div>
</template>

<script>
import HotType from "@/views/academic-headlines/hot-list/hot-type/index.vue";
import ArticleList from "@/components/one-article/type-one/index.vue";
import { DELETE, QUERY, QUERYED } from "@/services/dao.js";
import { showTimeFormat } from "@/js/common";
export default {
  data() {
    return {
      hidePageNav: true,
      pageNum: 0,
      pageSize: 12,
      totlePage: 0,
      newarticlelist: [],
      status: 1, //1-人气榜 2-综合榜 3-收藏榜
      displayState: true // 人气榜 综合榜 收藏榜板块的显隐状态
    };
  },
  created: function() {
    this.init();
  },
  mounted() {
    this.$root.Hub.$on("changeState", stateof => {
      this.displayState = stateof;
    });
  },
  destroyed() {
    // console.log("离开list页面");
    var stateof = false;
    this.$root.Hub.$emit("changeState2", stateof);
  },
  methods: {
    init() {
      this.searchInfo(this.pageSize, 0); ///热榜文章列表
    },
    //前一页
    prevPage(val) {
      this.pageNum = val - 1;
      this.searchInfo(this.pageSize, this.pageSize * this.pageNum);
    },
    //下一页
    nextPage(val) {
      this.pageNum = val - 1;
      this.searchInfo(this.pageSize, this.pageSize * this.pageNum);
    },
    //当前页
    currentPage(val) {
      this.pageNum = val - 1;
      this.searchInfo(this.pageSize, this.pageSize * this.pageNum);
    },
    changeHostList(status) {
      //切换热榜
      this.status = status;
      this.pageNum = 0;
      this.totlePage = 0;
      this.searchInfo(this.pageSize, 0);
    },
    async searchInfo(limit, offset) {
      let info = null;
      let type = "PopularityArticleList";
      if (this.status == 1) {
        type = "PopularityArticleList";
      } else if (this.status == 2) {
        type = "ComprehensiveArticleList";
      }
      // else if(this.status==3){

      //    type="CollectionArticleList";
      //  }
      else {
        type = "CollectionArticleList";
      }
      info = await QUERY(
        "post",
        "",
        type +
          "(limit: " +
          limit +
          ", offset: " +
          offset +
          ") {    id coverImg   createTime  imge  content  userid      commentNum    collectNum    browseNum    authorName    accessory    img    intro    keyword    praiseNum    reference    title     authorId   hotNum  }  " +
          type +
          "_aggregate(where: {}) {    aggregate {      count    }  }"
      );
      //  this.newarticlelist.splice(0,this.newarticlelist.length);
      this.newarticlelist = [];
      for (let i = 0; i < info.data[type].length; i++) {
        this.newarticlelist.push({
          title: info.data[type][i].title,
          intro: info.data[type][i].intro,
          name: info.data[type][i].authorName,
          time: showTimeFormat(info.data[type][i].createTime),
          collectNum: info.data[type][i].collectNum,
          userId: info.data[type][i].authorId,
          commentNum: info.data[type][i].commentNum,
          image:
            info.data[type][i].coverImg.indexOf("https://") != -1 ||
            info.data[type][i].coverImg.indexOf("http://") != -1
              ? info.data[type][i].coverImg
              : this.api.LoginURL.concat(info.data[type][i].coverImg),

          // image: this.api.LoginURL.concat(info.data[type][i].coverImg),
          id: info.data[type][i].id,
          hotNum: info.data[type][i].hotNum,
          praiseNum: info.data[type][i].praiseNum,
          // concernNum:info.data[type][i].concernNum,
          keyword: info.data[type][i].keyword,
          imge: this.api.LoginURL.concat(info.data[type][i].imge),
          userid: info.data[type][i].userid
        });
      }
      this.totlePage = info.data[type + "_aggregate"].aggregate.count;
      // console.log("this.newarticlelist: ", this.newarticlelist);
      // console.log(
      //   'this.totlePage=info.data[type+"_aggregate"].aggregate.count',
      //   (this.totlePage = info.data[type + "_aggregate"].aggregate.count)
      // );
      // console.log("info,", info);
    },
    changeStateTrue() {
      // console.log("1111111");
      this.displayState = false;
    },
    enter() {
      this.displayState = true;
    }
  },
  components: {
    "V-HotType": HotType,
    "V-ArticleList": ArticleList
  }
};
</script>

<style scoped>
@import url(~@/styles/page/index.css);
.container {
  width: 100%;
  text-align: center;
  /* border: 1px solid darkcyan; */
  background-color: #f7f7f7;
}
.container > .center {
  width: 70%;
  /* border: 1px solid firebrick; */
  margin-top: 30px;
  margin: auto;
  padding-bottom: 30px;
}

.container > .center > .content {
  /*width:90%会掉下来*/
  width: 100%;
  display: inline-block;
  text-align: center;
  /* border: 1px solid red; */
  position: relative;
}
.container > .center > .content > .left {
  display: inline-block;
  /* width: 65%;  */
  width: 90%;
  background-color: #ffffff;
}
/* .container > .center > .content > .right {
  display: none;
  width: 32%;
  vertical-align: top;
  margin-left: 30px;
  text-align: center;
  height: 250px;
  position: absolute;
  top: -65px;
  right: 17%;
} */
.right {
  /* width: 240px; */
  vertical-align: top;
  margin-left: 30px;
  text-align: center;
  height: 250px;
  position: absolute;
  top: 167px;
  right: 0%;
  display: inline-block;
  /* border:1px solid red */
}
.container > .center > .content > .right > div {
  display: inline-block;
}

/* 修改样式 */
.dis-block {
  display: block;
}
</style>
