<template>
  <div class="cards-wrapper">
    <a
      class="list-card"
      v-bind:class="status === 1 ? 'active' : ''"
      href="javaScript:void(0)"
      rel="noopener noreferrer"
      @click="changeStatus(1)"
      ><img
        src="//static.36krcdn.com/36kr-web/static/ic_renqi@3x.c4158afa.png"
        alt=""
        class="card-icon"
      />
      <div class="title-desc">
        <div class="title">人气榜</div>
        <div class="desc">实时人气阅读量展示</div>
      </div></a
    ><a
      class="list-card"
      v-bind:class="status === 2 ? 'active' : ''"
      href="javaScript:void(0)"
      rel="noopener noreferrer"
      @click="changeStatus(2)"
      ><img
        src="//static.36krcdn.com/36kr-web/static/ic_zonghe@3x.c56df05e.png"
        alt=""
        class="card-icon"
      />
      <div class="title-desc">
        <div class="title">综合榜</div>
        <div class="desc">推荐阅读量展示</div>
      </div></a
    ><a
      class="list-card"
      v-bind:class="status === 3 ? 'active' : ''"
      href="javaScript:void(0)"
      rel="noopener noreferrer"
      @click="changeStatus(3)"
      ><img
        src="//static.36krcdn.com/36kr-web/static/ic_shoucang@3x.3ecdab5c.png"
        alt=""
        class="card-icon"
      />
      <div class="title-desc">
        <div class="title">收藏榜</div>
        <div class="desc">收藏量最多展示</div>
      </div></a
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      status: 1, //1-人气榜 2-综合榜 3-收藏榜
    };
  },
  created: function() {
    this.init();
  },
  methods: {
    init() {
      this.changeStatus(this.status);
    },
    changeStatus(status) {
      this.status = status;
      this.$parent.changeHostList(status); //调用父组件的查询方法
    },
  },
};
</script>

<style scoped>
.cards-wrapper .active {
  width: 100%;
  background-color: #efefef;
}
.cards-wrapper {
  position: relative;
  width: 240px;
  display: inline-block;
  padding: 4px 0 0 0;
  border: 1px solid #e5e5e5;
  border-top: 0 solid #e5e5e5;
  background-color: white;
  border-radius: 2px;
  text-align: left;
}
.cards-wrapper > a {
  padding: 0 15px;
}
.cards-wrapper:before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  left: -1px;
  width: 100%;
  height: 4px;
  background: #fd6e05;
  border-radius: 2px 2px 0 0;
}
.cards-wrapper .list-card {
  width: 100%;
  /* background-color: white; */
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  height: 82px;
  border-bottom: 1px solid #e5e5e5;
}
.cards-wrapper .list-card .card-icon {
  display: inline-block;
  width: 42px;
  height: 42px;
  margin-right: 15px;
}
.cards-wrapper .list-card .title-desc .title {
  font-size: 16px;
  font-weight: bolder;
  color: #262626;
  height: 22px;
  line-height: 22px;
}
.cards-wrapper .list-card .title-desc .desc {
  font-size: 13px;
  color: #787878;
  height: 20px;
  line-height: 20px;
}
a {
  text-decoration: none;
}
</style>
